import React from 'react';
import { graphql, Link } from 'gatsby';
import styled from '@emotion/styled';
import Page from '../components/shared/page';
import PageHero from '../components/shared/page-hero';
import Container from '../components/shared/container';
import { colors } from '../styles/variables';
import IndexLayout from '../layouts';

const BlogPost = styled.article`
  h2 {
    > a {
      color: #000;
      &:hover {
        color: ${colors.brand};
      }
    }
  }
  .ReadMore {
    color: ${colors.brand1};
  }
`;

export interface BlogPageProps {
  data: {
    blog: {
      nodes: {
        id: string;
        excerpt: string;
        fields: {
          slug: string;
        };
        frontmatter: {
          date: string;
          title: string;
          author: string;
        };
      }[];
    };
  };
}

const Blog: React.FunctionComponent<BlogPageProps> = ({ data }) => {
  const posts = data.blog.nodes.sort((a, b) =>
    new Date(a.frontmatter.date) > new Date(b.frontmatter.date) ? -1 : 0,
  );
  return (
    <IndexLayout title="Blog">
      <Page>
        <PageHero>
          <h1>The StoreKick Blog</h1>
          See what we've been up to.
        </PageHero>
        <Container className="medium" style={{ paddingBottom: 0 }}>
          <article>
            {posts.map(post => (
              <BlogPost key={post.id}>
                <h2>
                  <Link to={post.fields.slug}>{post.frontmatter.title}</Link>
                </h2>
                <small>{post.frontmatter.date}</small>
                <p>{post.excerpt}</p>
                <Link className="ReadMore" to={post.fields.slug}>
                  Read More
                </Link>
              </BlogPost>
            ))}
          </article>
        </Container>
      </Page>
    </IndexLayout>
  );
};

export default Blog;

export const pageQuery = graphql`
  query BlogQuery {
    blog: allMarkdownRemark {
      nodes {
        excerpt
        id
        fields {
          slug
        }
        frontmatter {
          date
          title
          author
        }
      }
    }
  }
`;
